<template>
    <div id="mainContainer">
      <div class="content-wrap">

        <div id="pdf-area">

          <div class="pdf-tool-bar tool-bar-left" :class="{'single-mode':isSingleMode}">

            <div class="tool-bar-left-item" :class="{'single-mode-tool':isSingleMode}">
              <contract-diff-tool-bar
                  ref="originContractTool"
                  :show-back-btn="true"
                  :scaleValue="scaleValue"
                  :total-page="10"
                  :scaleOptions="scaleOptions"
                  :emitter="emitter"
                  :isPDF="originIsPDF"
                  @fileTypeChange="renderPdf('left',$event,true)"
                  @scaleChange="(val)=>{scaleValue = val}"
                  :tip="{
                    text:'旧版',
                    tip:leftVersionTip,
                  }"
                  :file-type="currentOriginFileType"
                  style="font-size: 4.1px"
                  class="contractToolBar"></contract-diff-tool-bar>
            </div>

            <hr id="tool-bar-left-hr" :class="{'single-mode':isSingleMode}">
            <div class="tool-bar-left-item" >
              <contract-diff-tool-bar
                  ref="diffContractTool"
                  :scaleValue="scaleValue"
                  :total-page="10"
                  :scaleOptions="scaleOptions"
                  :emitter="emitter"
                  :isPDF="diffIsPDF"
                  @fileTypeChange="renderPdf('right',$event,true)"
                  @scaleChange="(val)=>{scaleValue = val}"
                  :tip="{
                    text:'新版',
                    tip:rightVersionTip
                  }"
                  :file-type="currentDiffFileType"
                  style="font-size: 4.1px"
                  class="contractToolBar"></contract-diff-tool-bar>
            </div>
          </div>

          <div class="pdf-wraps">
            <div class="pdf-wrap" v-loading="originSwitchFileLoading" :class="{'single-mode':isSingleMode}" id="pdf-wrap-old">
              <div class="pdf-container custom-scroller" :id="pdfContainerId" v-show="isDoubleMode" >
<!--                <div class="pdf-fileTip">-->
<!--                  <div class="pdf-fileTip-icon">-->
<!--                    <svg-icon name="doc" height="100%" width="100%"></svg-icon>-->
<!--                  </div>-->
<!--                  <span class="pdf-fileTip-fileName">{{originFileName}}</span>-->
<!--                </div>-->
                <div :id="pdfViewerId" class="pdfViewer">
                </div>
              </div>
            </div>
            <div class="pdf-wrap" v-loading="diffSwitchFileLoading" :class="{'single-mode':isSingleMode}" id="pdf-wrap-new" >
              <div class="pdf-container custom-scroller" :id="diffPdfContainerId" >
<!--                <div class="pdf-fileTip">-->
<!--                  <div class="pdf-fileTip-icon">-->
<!--                    <svg-icon name="doc" height="100%" width="100%"></svg-icon>-->
<!--                  </div>-->
<!--                  <span class="pdf-fileTip-fileName">{{diffFileName}}</span>-->
<!--                </div>-->
                <div :id="diffFilePdfViewerId" class="pdfViewer">
                </div>
              </div>
            </div>
          </div>

        </div>
        <div class="alter-area">
<!--          <div v-if="$route.query.hasPendingVersion" style="display: flex;align-items: center;padding: 2px">-->
<!--            <el-button size="small" type="primary" @click="acceptSignedDiff">同意</el-button>-->
<!--            <el-button size="small" @click="cancelSignedDiff">不同意</el-button>-->
<!--          </div>-->
          <div class="pdf-tool-bar tool-bar-right">
            <div class="ptb-locate-center">
              <span class="tbr-changesCount">
              <span style="color:var(--lightBlue)">{{alertData.length}}</span><wbr>
              <span> 处更改</span>
            </span>
              <el-select
                  size="mini"
                  style="width: 110px"
                  v-model="diffDisplayMode"
                  @change="switchDiffDisplayMode">
                <el-option
                    label="单文件模式"
                    :value="DiffDisplayMode.SINGLE"
                ></el-option>
                <el-option
                    label="双文件模式"
                    :value="DiffDisplayMode.DOUBLE"
                ></el-option>
              </el-select>
            </div>
          </div>
          <div class="alert-cust-container custom-scroller" :id="alertContainerId">
            <div
                v-for="(alertInfo,index) in alertData"
                :key="alertInfo.id"
                :data-id="alertInfo.id"
                @click="alertClick(alertInfo)"
                :ref="'alertInfoBox'+alertInfo.id"
                :data-expand-status="alertInfo.calculateInfo.status"
                class="diffAlertBox"
                :class="getDiffBoxClass(alertInfo)"
            >
              <div class="diffResultType">
                <span>{{index+1}}.{{getDiffAlertTypeDesc(alertInfo)}}</span>
              </div>
              <div v-if="getDeleteText(alertInfo)!==''" class="diffText">
                <span class="deleteText">{{(getDeleteText(alertInfo)).slice(0,60)}}</span>
                <span v-if="(getDeleteText(alertInfo)).length > 60" class="changeText_hiddenContentSummary">+ {{getDeleteText(alertInfo).length - 60}} 字</span>
              </div>
              <div v-if="getInsertText(alertInfo)!==''"  class="diffText">
                <span class="insertText">{{(getInsertText(alertInfo)).slice(0,60)}}</span>
                <span v-if="(getInsertText(alertInfo)).length > 60" class="changeText_hiddenContentSummary">+ {{getInsertText(alertInfo).length - 60}} 字</span>
              </div>
              <div class="diffResultAggravation">
                <span class="optionDiff optionDiff-delete" :class="{optionDiffNoChange:getDeleteText(alertInfo).length == 0}">{{getDeleteText(alertInfo).length > 0 ? '- ' + getDeleteText(alertInfo).length : 0}}</span>
                <hr class="optionDiff-hr">
                <span class="optionDiff optionDiff-add" :class="{optionDiffNoChange:getInsertText(alertInfo).length == 0}">{{getInsertText(alertInfo).length > 0 ? '+ ' + getInsertText(alertInfo).length : 0}}</span>
              </div>
            </div>
            <div v-if="alertDataReady&&alertData.length===0" id="noAlert">
              <div id="noAlertContent" style="height:100%;">
<!--                <img src="https://uranus-static.oss-accelerate.aliyuncs.com/xiezuocat/isOk.png" width="100%">-->
                <div style="text-align: center;margin-top:50px;font-weight: bold">没有差异</div>
                <div style="text-align: center;flex: 1;position:relative">
                  <div class="no-diff-container">
                    <img id="no-diff-img" style="width:100%" src="../../../public/static/img/no_diff.png">
                  </div>

                </div>
              </div>
            </div>

          </div>

        </div>

        </div>


    </div>
</template>

<script>
import {
  acceptSignedDiff, cancelSignedDiff,
  convertContentIndexToPdfIndex,
  diffContractVersion, getContractVersionPreviewStatus, rejectSignedDiff,
  getContractViewDetail, getContractVersion
} from "../../api/api";
import {BriefEmitter, colorLog, MetaLogger} from "../../util";
    import * as $ from "jquery";
    import {ContractFileType} from "../../components/common/contractConstant";
    import {getContractVersionPdfUrl, initPdfView, ScaleSpecialValue} from "../../components/common/pdfView";
import {ElLoading, ElMessage} from "element-plus";
    import ContractDiffToolBar from "../../components/contract/ContractDiffToolBar";
    import { h,toRaw } from "vue";


    const DiffAlertType = {
        INSERT_BEFORE: 1,
        INSERT_AFTER: 2,
        DELETE_BEFORE: 3,
        DELETE_AFTER:4,
        REPLACE: 5,
    }

    const AlertShowType = {
        HORIZONTAL: 0,//水平划线模式
        VERTICAL: 1, // 垂直划线模式
        DOC: 2,//显示全文级别的错误
        NONE: 3,//不显示划线
        INSERT:4,//显示插入类型的划线
        DELETE:5,//显示删除类型的划线。
    }
    const ErrorType = {
        ALL: -1, // 所有
        OTHER: 0, // 其它
        TYPO: 1, // 错别字
        PUNCTUATION_MISUSE: 2, // 标点符号误用
        WORD_ORDER_ERROR: 3, // 语序错误
        SYNONYMOUS_CONFUSION: 4, // 同义词混淆
        GRAMMAR_ERROR: 5, // 语法错误
        ADVANCED_TIPS: 100,//只作为分界线 不可选，高级提示的所有错误类型都大于改值
        DATE_ERROR: 101,//日期错误
        AMOUNT_ERROR: 102,//金额错误
        BRAND_ERROR: 103,//专用名词错误
        DISUNITY_ERROR: 104,//重复定义
        SERIAL_ERROR: 105,//序标错误
        LAW_DEFINE: 106,
        ERROR_LINK: 107,
        CONTRACT_RISK: 51, //合同风险
        NONSTANDARD_CLAUSE: 52, //非标准条款
        CUSTOM_COMMENT: 53, // 自定义批注
        STANDARD: 54, // 规范错误
    }
    // 标记的目标类型
    const MarkTargetType = {
        ORIGIN_FILE: 1,//源文件
        DIFF_FILE: 2,// diff文件
    };
    // 某一条提示信息的展示状态
    const AlertDisplayStatus = {
        COLLAPSE: 0, // 折叠状态
        EXPAND: 1 // 展开状态
    }
    // diff的显示模式
    const DiffDisplayMode = {
        SINGLE:0,// 单文件模式
        DOUBLE:1,// 双文件模式
    }


    const ScaleOptions = [
        {name:'自动缩放',value:'auto'},
        {name:'实际大小',value:'page-actual'},
        {name:'适合页面',value:'page-fit'},
        {name:'适合页宽',value:'page-width'},
        {name:'50%',value:'0.5'},
        {name:'100%',value:'1'},
        {name:'150%',value:'1.5'},
        {name:'200%',value:'2'},
        {name:'300%',value:'3'},
        {name:'400%',value:'4'},
    ];
    export default {
        name: "ContractDiff",
        components: {ContractDiffToolBar},
        data() {
            return {
                originVersionId: this.$route.query.oId,
                diffVersionId: this.$route.query.dId,
                contractId: this.$route.params.contractId,

                currentOriginFileType:'text',
                currentDiffFileType:'text',

                //用于保存pdf的扫描版
                originIsPDF: this.$route.query.os === 'false' ? false : this.$route.query.os,
                diffIsPDF: this.$route.query.ds === 'false' ? false : this.$route.query.ds,

                originSwitchFileLoading:false,
                diffSwitchFileLoading:false,
                //用于显示文件名
                // originFileName: decodeURIComponent(this.$route.query.oName),
                // diffFileName: decodeURIComponent(this.$route.query.dName),
                originPdfInfoInitStarted:false,
                diffPdfInfoInitStarted:false,
                originFileId: null,
                diffFileId:null,
                alertDataBeforeHandle:null,//这个用来存放diff的结果（未经过转换index）
                alertData: [],
                pdfContainerId: 'pdf-container',
                pdfViewerId: 'pdf-viewer',
                diffPdfContainerId:'pdf-container2',
                diffFilePdfViewerId:'diff-pdf-viewer',
                editorId: 'pdf-viewer',
                alertContainerId:'alertContainer',
                ContractFileType: ContractFileType,
                originPdfInfo: null,
                originPdfContent: null,
                diffPdfInfo:null,
                diffPdfContent:null,
                alertHighlightContainerClass: 'alertLayer',
                alertHighlightClass: 'highlightContainer',
                alertsOffsetTop: 0,
                DiffAlertType: DiffAlertType,
                AlertDisplayStatus: AlertDisplayStatus,

                alertDataReady: false,
                freezeScrollListener: false,//是否冻结点对"显示更多提示"的判断。
                unFreezeScrollListenerTimeout: null,//冻结后需要解冻，这个是解冻的定时器
                loadingInstance:null,
                diffDisplayMode:DiffDisplayMode.DOUBLE,
                DiffDisplayMode:DiffDisplayMode,
                scaleOptions:ScaleOptions,
                scaleValue: ScaleSpecialValue.PAGE_WIDTH,
                emitter:new BriefEmitter(),

                unfreezeTimeout:null,
                timeout:null,

                contractDetail:null,
                leftVersionTip:'',
                rightVersionTip:'',
                signDiffTips:null
            };
        },
        mounted() {
            if (!this.checkParameter()) {
                return;
            }

            this.addEditorEventListener();
            this.startDiff();
            this.updateTip();
        },
      beforeUnmount() {
        this.emitter.destroy();
      },
      watch:{
            alertData(val) {
                this.refreshAlertDataMap(val)
                this.refreshErrorHighlight(val);
            },
            scaleValue:function (val) {
                this.scaleChange(val);
            }
        },
        computed:{
            isSingleMode:function(){
                return this.diffDisplayMode === DiffDisplayMode.SINGLE;
            },
            isDoubleMode:function () {
                return this.diffDisplayMode === DiffDisplayMode.DOUBLE;
            },
            scrollerId:function () {
                return this.diffPdfContainerId;
            }
        },
        methods: {
          _initPdfView({versionId, pdfContainerId, pdfViewerId, addPdfEventListener, defaultScale,ocrContentPdf}){
            let  proxyResolve = null;
            const pro = new Promise((resolve)=>{
              proxyResolve = resolve;
            });

            function checkStatus(){
              MetaLogger.warning("checkStatus" + new Date().getTime())
              getContractVersionPreviewStatus(versionId).then(res => {
                if(res.data.code===0) {
                  let result = res.data.data;
                  this.showMessageByCode(result);
                  if (result) {
                    // 转换成功
                    this.loading = false;
                    proxyResolve(
                        initPdfView({
                          fileUrl: getContractVersionPdfUrl(versionId, ocrContentPdf),
                          pdfContainerId,
                          pdfViewerId,
                          addPdfEventListener,
                          defaultScale,
                        })
                    )
                  } else {
                    // 仍在转换中
                    this.loading = true;
                    this.timeout = setTimeout(() => checkStatus(), 1000);
                 }
                }else{
                  this.$message.error(res.data.msg)
                }
              }).catch(error => {
                this.$message.error('文档预览失败')
              })
            }
            // eslint-disable-next-line no-func-assign
            checkStatus = checkStatus.bind(this);
            checkStatus();

            return pro;
          },
          showMessageByCode: function (code) {
            switch (code) {
              case 0:
                //ElMessage.info('文件正在转换中，请稍后重试');
                return;
              case 2:
                this.$message.error('不支持的类型！');
                return;
              case 3:
                ElMessage.info('文件预览出错！');
                return;
              case 4:
                //ElMessage.info('文件转换超时，请稍后重试');
                return;
              case -1:
                ElMessage.info('回收站不支持预览！');
                return;
              case -2:
                ElMessage.info('你没有此操作的权限！');
                return;
            }
            // code小于0，其实也相当于<-2 ,因为上面已经对-1，-2做了区分
            if (code < 0) {
              ElMessage.info('文件不存在！');
              return;
            }
          },
            renderPdf(side,fileType,isSwitch,callback){
              if(isSwitch){
                this.$refs.diffContractTool.$forceUpdate();
                this.$refs.originContractTool.$forceUpdate();
              }

              if(side == 'left'){
                MetaLogger.info('加载旧版',this.originFileId);
                this.currentOriginFileType = fileType;
                this.originSwitchFileLoading = true;
                this._initPdfView({
                  versionId: this.originVersionId,
                  pdfContainerId: this.pdfContainerId,
                  pdfViewerId: this.pdfViewerId,
                  addPdfEventListener: this.addSourcePdfEventListener,
                  defaultScale:this.scaleValue,
                  ocrContentPdf:fileType == 'text'
                  // autoScaleFitViewer: true
                }).then(originPdfInfo => {
                  this.originSwitchFileLoading = false;
                  this.originPdfInfo = originPdfInfo;
                  this.$refs.originContractTool.setPdfInfo(originPdfInfo);
                  if(isSwitch){
                    this.$refs.diffContractTool.setPdfInfo(this.diffPdfInfo);
                  }
                  this.originPdfContent  = this.originPdfInfo.replaceAllSpace(originPdfInfo.pagesContent.join(""))
                  callback && callback();
                })
              }else{
                MetaLogger.info('加载新版',this.diffFileId);
                this.currentDiffFileType = fileType;
                this.diffSwitchFileLoading = true;
                this._initPdfView({
                  versionId:this.diffVersionId,
                  pdfContainerId: this.diffPdfContainerId,
                  pdfViewerId: this.diffFilePdfViewerId,
                  addPdfEventListener: this.addDiffPdfEventListener,
                  defaultScale:this.scaleValue,
                  ocrContentPdf:fileType == 'text'
                  // autoScaleFitViewer:true
                }).then(diffPdfInfo =>{
                  this.diffSwitchFileLoading = false;
                  this.diffPdfInfo = diffPdfInfo;
                  if(isSwitch){
                    this.$refs.originContractTool.setPdfInfo(this.originPdfInfo);
                  }
                  this.$refs.diffContractTool.setPdfInfo(diffPdfInfo);
                  this.diffPdfContent = this.diffPdfInfo.replaceAllSpace(diffPdfInfo.pagesContent.join(""))
                  callback && callback();
                });
              }
            },
            checkParameter: function () {
                if (!this.originVersionId) {
                    this.$message.error('参数错误');
                    return false;
                }
                if (!this.diffVersionId) {
                    this.$message.error('参数错误');
                    return false;
                }
                if (!this.contractId) {
                    this.$message.error('参数错误');
                    return false;
                }
                return true;
            },
            switchDiffDisplayMode:function(mode){
                this.switchDiffDisplayModeInitPdf(mode);
                this.$nextTick(()=>{
                    // this.diffPdfInfo.fitScale()
                    this.scaleValue = ScaleSpecialValue.PAGE_WIDTH;
                    this.scaleChange(this.scaleValue);
                    this.refreshErrorHighlight(this.alertData)
                })
            },
            scaleChange: function (val) {
                console.log('scale change:',val)
                if (this.diffPdfInfo) {
                    toRaw(this.diffPdfInfo).setScale(val);
                }
                if (this.originPdfInfo) {
                    toRaw(this.originPdfInfo).setScale(val);
                }
            },
            switchDiffDisplayModeInitPdf:function(mode){
                if (mode === DiffDisplayMode.DOUBLE&&!this.originPdfInfoInitStarted) {
                    // 如果是切换成双文件模式
                  this.renderPdf('left',this.currentOriginFileType,false,()=>{
                    this.pdfReady(this.alertDataBeforeHandle);
                  })
                }
            },
            startDiff: function () {
                if (!this.loadingInstance) {
                    this.loadingInstance = ElLoading.service({ fullscreen: true, body: true, lock: true, spinner: 'el-icon-loading' });
                }
                this.loadingInstance.setText("对比中")
                diffContractVersion( this.originVersionId, this.diffVersionId).then(res => {
                    console.log('diff result:', res);
                    if (res.data.code === 0) {
                        this.loadingInstance.close();
                        this.diffSuccess(res);
                    } else if (res.data.code === 1001) {
                        // 说明还没有准备好，需要等待一段时间再次diff。
                        setTimeout(() => {
                            this.startDiff()
                        }, 2000);
                    } else {
                        this.loadingInstance.setText("对比失败")
                        this.$message.error(res.data.msg);
                    }
                }).catch(error => {
                    this.loadingInstance.setText("对比失败")
                    // this.$message.error('对比失败');
                });
            },
            diffSuccess: function (res) {
                let alerts = res.data.data.alerts;
                this.alertDataBeforeHandle = alerts;
                this.originFileId = res.data.data.originFileId;
                this.diffFileId = res.data.data.diffFileId;
                if(this.$route.params.signedMode){
                  this.signDiffTips = ElMessage({
                    type: 'success',
                    message: h('p', {style:'padding-right:20px'}, [
                      h('span', null, '合同设为了已签署，此签署版与定稿版存在'),
                      h('span', { style: 'color: red' }, alerts.length),
                      h('span', null, '处差异'),

                    ]),
                    showClose: true,
                    duration:0
                  })
                }

                this.renderPdf('right','text',false,()=>{
                  this.pdfReady(alerts);
                })
                if (this.diffDisplayMode === DiffDisplayMode.DOUBLE) {
                  this.renderPdf('left','text',false,()=>{
                    this.pdfReady(alerts);
                  })
                }
            },
            checkDiffResult:function(alerts){
              console.log("check diff result****************")
              if (alerts.length === 1) {
                if (alerts[0].start === 0 && alerts[0].end === 0) {
                  this.$message.error("未识别出左侧文档内容或左侧文档内容为空")
                  return false;
                }else if (alerts[0].diffStart === 0 && alerts[0].diffEnd === 0) {
                  this.$message.error("未识别出右侧文档内容或右侧文档内容为空")
                  return false;
                }
              }
              return true;
            },
            addSourcePdfEventListener: function (eventBus) {
                eventBus.on('pagerendered', (page) => {
                    // console.log('origin file pageRendered',page)
                    // 有时会出现虽然触发了pagerendered但是，页面节点还未渲染完毕的情况，所以做个timeout延迟处理。
                    setTimeout(()=>{
                        // console.log('origin file real do pageRendered',page)
                        this.sourceFilePageRendered(page.pageNumber);
                    },100);
                });
            },
            addDiffPdfEventListener:function(eventBus){
                eventBus.on('pagerendered', (page) => {
                    // console.log('diff file pageRendered',page)
                    // 有时会出现虽然触发了pagerendered但是，页面节点还未渲染完毕的情况，所以做个timeout延迟处理。
                    setTimeout(()=>{
                        // console.log('diff file real do pageRendered',page)
                        this.diffFilePageRendered(page.pageNumber);
                    },100);
                });
            },
            pdfReady: function (alerts) {
                if ( !this.diffPdfInfo) {
                    return;
                }
                if (this.diffDisplayMode === DiffDisplayMode.DOUBLE && !this.originPdfInfo) {
                    return
                }
              if (!this.checkDiffResult(alerts)) {
                return;
              }
              this.convertAlertIndex(this.originVersionId,this.originPdfContent,this.diffVersionId,this.diffPdfContent, alerts);
            },
            convertAlertIndex: function (originVersionId,originPdfContent,diffVersionId,diffPdfContent, alerts) {
                convertContentIndexToPdfIndex({originVersionId,originPdfContent,diffVersionId,diffPdfContent, alerts}).then(res => {
                    if (res.data.code !== 0) {
                        this.$message.error('diff失敗')
                        return
                    }
                    let newAlerts = res.data.data;
                    this.startToMark(newAlerts)
                }).catch(error => {
                    console.error(error)
                    this.$message.error('diff失敗')
                })
            },
            startToMark: function (newAlertData) {
                console.log('start to mark', newAlertData)
                this.alertData = this.calculateAndModifyAlert(newAlertData)
                this.alertDataReady = true
                setTimeout(() => {
                    this.refreshErrorHighlight(newAlertData);
                }, 1000)

            },
            removeHighlight: function () {
                $(`.${this.alertHighlightClass}`).remove()
            },
            refreshAlertDataMap: function (alertData){
                let res = new Map();
                for (let i = 0; i < alertData.length; i++) {
                    res.set(alertData[i].id, alertData[i]);
                }
                this.alertDataMap = res;
            },
            /**
             * 刷新划线区域
             * @param {[{id,calculateInfo,showType,errorType}]} alertData
             */
            refreshErrorHighlight: function (alertData) {
                //删除旧的highlight节点
                this.removeHighlight()
                if (alertData.length === 0) {
                    return
                }

                if (this.diffDisplayMode === DiffDisplayMode.SINGLE) {
                    this.markVisibleAlert(alertData,MarkTargetType.DIFF_FILE);
                }else{
                    this.markVisibleAlert(alertData,MarkTargetType.ORIGIN_FILE)
                    this.markVisibleAlert(alertData,MarkTargetType.DIFF_FILE);
                }
            },
            getPageIndexInfoByTargetType: function (alert, targetType) {
                if (targetType === MarkTargetType.ORIGIN_FILE) {
                    return alert.calculateInfo.pageIndexInfo
                }else if (targetType === MarkTargetType.DIFF_FILE) {
                    return alert.calculateInfo.diffPageIndexInfo
                }
                return alert.calculateInfo.pageIndexInfo
            },
            getPdfInfoByTargetType:function(targetType){
                if (targetType === MarkTargetType.ORIGIN_FILE) {
                    return this.originPdfInfo
                }else if (targetType === MarkTargetType.DIFF_FILE) {
                    return  this.diffPdfInfo;
                }
                return this.originPdfInfo;
            },
            markVisibleAlert:function(alertData,targetType){
                let self = this;
                let pdfInfo = this.getPdfInfoByTargetType(targetType);
                //先获取所有加载完毕的页码
                const visiblePageNum = pdfInfo.getRenderFinishPageNum(true);
                //根据已经加载出来的页码，来过滤能够显示出来的错误提示
                let canMarkDiffAlerts = alertData.filter(a => {
                    if (!this.getPageIndexInfoByTargetType(a,targetType)) {
                        return false;
                    }
                    return visiblePageNum.indexOf(this.getPageIndexInfoByTargetType(a,targetType)[0].startPage) !== -1;
                })
                //在已经加载出的页面内画出对应错误提示。
                canMarkDiffAlerts.forEach(alert => {
                    self.getPageIndexInfoByTargetType(alert,targetType).forEach(pageIndexInfo => {
                        //将页内坐标（包含空格）修复成页内元素坐标（不包含空格）
                        const pageRanges = pdfInfo.getFixedPageRanges(pageIndexInfo)
                        if (pageRanges == null) {
                            //说明该页尚未加载完成，也就不用画错误提示。
                            return;
                        }
                        //根据该坐标去取到对应的document的range对象。
                        let documentRanges = pdfInfo.getRangeAndAppendLayers(pageRanges, visiblePageNum);
                        documentRanges.forEach(rangeInfo => {
                            //根据range对象对应的矩形区域画出错误提示。
                            self.createAlertMarker(rangeInfo.appendLayer, rangeInfo.documentRange, alert,targetType);
                        })
                    });
                });
            },
            createAlertMarker: function (appendLayer, selectRange, alert,targetType) {

                //拿到range的前后两个span dom对象
                let rangeSpanPro = selectRange.startContainer.parentElement;
                let rangeSpanFor = selectRange.endContainer.parentElement;
                //存放这个range包含的node
                const nodesInRange = [];
                let tempNode = rangeSpanPro;

                if(rangeSpanPro != rangeSpanFor){
                  //两个span dom对象 不是 同一个对象

                  while(tempNode.nextSibling != rangeSpanFor){

                    nodesInRange.push(tempNode);
                    tempNode = tempNode.nextSibling;
                  }
                  nodesInRange.push(rangeSpanFor);
                }else{
                  //两个span dom对象 是 同一个对象
                  nodesInRange.push(rangeSpanPro);
                }

                //上一次遍历的是br元素
                let isBr = false;
                //nodes里面span的缩放值
                const computedScales = nodesInRange.map(span=>{
                  if(span.nodeName.toLowerCase() != 'br'){
                    isBr = false;
                  }else{
                    isBr = true;
                  }
                  //通过计算方法获取浏览器真实渲染出来的span的字体大小
                  const realFontSize = Number(getComputedStyle(span).fontSize.slice(0,-2));
                  //span标签上style属性上设定的span字体大小
                  const setFontSize = Number(span.style.fontSize.slice(0,-2));

                  //计算出缩放值
                  const scale = (setFontSize / realFontSize).toFixed(2);
                  const match = span.style.transform.match(/scaleX\((.+)\)/g);
                  span.style.transform = (scale < 1 ? `scale(${scale})`:'') + (match ? ` scaleX(${RegExp.$1})` : '');

                  return scale;
                }).filter(item => item);

                let selectRangeRects = selectRange.getClientRects();

                const offset = appendLayer.offset();
                let leftOffset = offset.left;
                let topOffset = offset.top;
                const self = this
                const lineBoxType = this.getAlertShowType(alert)
                const firstMarkerClasses = self.getAlertHighlightClassNames(alert,targetType);
                let notFirstMarkerClasses = this.excludeFirstMarkerClass(firstMarkerClasses);
                const firstMarkerClassStr = firstMarkerClasses.join(" ")
                const notFirstMarkerClassStr = notFirstMarkerClasses.join(" ")
                selectRangeRects = Array.from(selectRangeRects).filter(rect=>{
                    return rect.width !== 0 && rect.height !== 0
                }).sort((a, b) => {
                    if (Math.abs(a.top - b.top) > (a.height + b.height) * 0.5 * 0.5) {
                        return a.top - b.top;
                    }
                    if (a.left !== b.left) {
                        return a.left - b.left;
                    }
                    return 0
                })
                const mergedRects = lineBoxType === AlertShowType.VERTICAL ? this.mergeRectsByVerticalShowType(selectRangeRects, offset) : this.mergeRectsByAdjacent(selectRangeRects);
                for (let i = 0; i < mergedRects.length; i++) {
                    let mergedRect = mergedRects[i];
                    //由上面计算的缩放值造成的left偏移值
                    // const leftOffsetByScale = mergedRect.width * (1 - needScale)/2

                  appendLayer.append(
                        `<div class='${i===0?firstMarkerClassStr:notFirstMarkerClassStr}' data-target="${targetType}" data-id="${alert.id}" style='left:${mergedRect.left - leftOffset }px;top:${mergedRect.top - topOffset }px;width:${mergedRect.width}px;height:${mergedRect.height}px;'></div>`
                    )
                }
            },
            /**
             * 对获取到的DOMRectList里面的DOMRect进行去重，防止同一个位置出现多个选区选中,并将左右相邻很近的rect尽量连接起来。
             * @param {DOMRectList} selectRangeRects
             */
            mergeRectsByAdjacent: function (selectRangeRects) {
                if (selectRangeRects.length < 2) {
                    return selectRangeRects
                }
                let result = [];
                let mergeRect = {
                    top: selectRangeRects[0].top,
                    left: selectRangeRects[0].left,
                    width: selectRangeRects[0].width,
                    height: selectRangeRects[0].height
                };
                for (let i = 1; i < selectRangeRects.length; i++) {
                    let domEle = selectRangeRects[i]
                    if (this.rectCanMerge(mergeRect, domEle)) {
                        //如果前一个rect和现在的rect能够merge，则对它们进行融合
                        let maxBottom = Math.min(mergeRect.top + mergeRect.height, domEle.top + domEle.height);
                        let minTop = Math.min(mergeRect.top, domEle.top);

                        let maxRight = Math.max(mergeRect.left + mergeRect.width, domEle.left + domEle.width);
                        let minLeft = Math.min(mergeRect.left, domEle.left);

                        mergeRect.top = minTop;
                        mergeRect.height = maxBottom - minTop;
                        mergeRect.left = minLeft;
                        mergeRect.width = maxRight - minLeft;
                    } else {
                        //如果不能够merge，则将mergeRect作为一个range放到结果里面
                        result.push(Object.assign({}, mergeRect));
                        //并将当前rect作为mergeRect
                        mergeRect.top = domEle.top;
                        mergeRect.left = domEle.left;
                        mergeRect.width = domEle.width;
                        mergeRect.height = domEle.height;
                    }
                }
                //将最后一个rect放入结果中。
                result.push(Object.assign({}, mergeRect));
                return result;
            },
            /**
             * 判断两个矩形是否相交
             * @param {{top,left,width,height}} rect1 第一个矩形
             * @param {{top,left,width,height}} rect2 第二个矩形
             * @returns {boolean}
             */
            rectCanMerge: function(rect1,rect2){
                //x方向，两个矩形的中心点的距离
                const xCenterDistance = Math.abs((rect2.left + rect2.width / 2) - (rect1.left + rect1.width / 2))
                //x方向，两个矩形的边长的一半
                const xHalfOfTotalWidth = (rect1.width + rect2.width) / 2;
                //y方向，两个矩形的中心点的距离
                const yCenterDistance = Math.abs((rect2.top + rect2.height / 2) - (rect1.top + rect1.height / 2))
                //y方向，两个矩形的边长的一半
                const yHalfOfTotalHeight = (rect1.height + rect2.height) / 2;
                //x方向可以融合的宽度差，这里把高度作为一个字的宽度，取一个字的百分之70作为可以融合的宽度差
                const mergeWidthDiff = yHalfOfTotalHeight * 0.7;
                const maxHeightDiff = yHalfOfTotalHeight * 0.1;
                //如果相交，则两个中心点之间的距离肯定小于等于该方向的边长和的一半。
                return xCenterDistance - mergeWidthDiff <= xHalfOfTotalWidth && yCenterDistance + maxHeightDiff <= yHalfOfTotalHeight;
            },
            /**
             * 根据竖直显示模式来合并矩形区域
             * @param {[{top,left,width,height}]} selectRangeRects
             * @param {[{top,left}]} offset
             */
            mergeRectsByVerticalShowType: function (selectRangeRects, offset) {
                if (selectRangeRects.length === 0) {
                    return [];
                }
                let pageRect = this.originPdfInfo.getPeerPageRect()
                let res = {
                    top: selectRangeRects[0].top,
                    // left:selectRangeRects[0].left,
                    // 划线统一从左到右
                    left: offset.left + 15,
                    // width:selectRangeRects[0].width,
                    width: pageRect.width - 30,
                    height: selectRangeRects[0].height
                };
                if (selectRangeRects.length < 2) {
                    return [res];
                }
                for (let i = 0; i < selectRangeRects.length; i++) {
                    // let x2 = Math.max(res.left + res.width, selectRangeRects[i].left + selectRangeRects[i].width);
                    // res.left = Math.min(res.left, selectRangeRects[i].left);
                    // res.width = x2 - res.left;

                    let y2 = Math.max(res.top + res.height, selectRangeRects[i].top + selectRangeRects[i].height);
                    res.top = Math.min(res.top, selectRangeRects[i].top);
                    res.height = y2 - res.top;
                }
                return [res]
            },
            /**
             * 根据alert的信息进行计算，并将计算结果修改到alert信息里面去
             * @param newAlertData
             */
            calculateAndModifyAlert: function (newAlertData) {

                let diffRanges = [];
                newAlertData.forEach((info) => {
                    if (!(info.diffStart === 0 && info.diffEnd === 0)) {
                        diffRanges.push({start: info.diffStart, end: info.diffEnd});
                    }
                })
                const diffConverteRangeInfo = this.diffPdfInfo.fullTextIndexesConvertToPageIndexInfo(diffRanges)
                let diffRangeIndex = 0;
                newAlertData.forEach((info, index) => {
                    if (!info.calculateInfo) {
                        info.calculateInfo={status: AlertDisplayStatus.COLLAPSE}
                    }
                    if (!(info.diffStart === 0 && info.diffEnd === 0)) {
                        diffRanges.push({start: info.diffStart, end: info.diffEnd});
                        info.calculateInfo.diffPageIndexInfo = diffConverteRangeInfo.slice(diffRangeIndex, diffRangeIndex + 1);
                        diffRangeIndex++;
                    }
                })

                // 如果当前不是处于双文件模式，则不用进行后面的计算。
                if (!(this.diffDisplayMode === DiffDisplayMode.DOUBLE)) {
                    return newAlertData;
                }

                // alert需要转换的range和每一个alert的range的size
                let originRanges = [];
                newAlertData.forEach((info) => {
                    if (!(info.start === 0 && info.end === 0)) {
                        originRanges.push({start: info.start, end: info.end});
                    }
                })
                const originConvertedRangeInfo = this.originPdfInfo.fullTextIndexesConvertToPageIndexInfo(originRanges)
                let originRangeIndex = 0;
                newAlertData.forEach((info, index) => {
                    if (!info.calculateInfo) {
                        info.calculateInfo={status: AlertDisplayStatus.COLLAPSE}
                    }
                    if (!(info.start === 0 && info.end === 0)) {
                        info.calculateInfo.pageIndexInfo = originConvertedRangeInfo.slice(originRangeIndex, originRangeIndex + 1);
                        originRangeIndex++;
                    }
                })
                return newAlertData;
            },


            getAlertShowType: function (alert) {
                if (AlertShowType.VERTICAL === alert.showType) {
                    return AlertShowType.VERTICAL
                } else if (AlertShowType.DOC === alert.showType) {
                    return AlertShowType.DOC
                } else if (AlertShowType.NONE === alert.showType) {
                    return AlertShowType.NONE
                }
                return AlertShowType.HORIZONTAL;
            },
            isInsertAlert:function(alert){
                return DiffAlertType.INSERT_BEFORE === alert.alertType || DiffAlertType.INSERT_AFTER === alert.alertType;
            },
            isDeleteAlert:function(alert){
                return DiffAlertType.DELETE_BEFORE === alert.alertType || DiffAlertType.DELETE_AFTER === alert.alertType;
            },
            excludeFirstMarkerClass:function(classNames){
                let res = [].concat(classNames)
                let onlyFirstMarkerClass=['marker_left_insert','marker_right_insert'];
                for (let i = 0; i < onlyFirstMarkerClass.length; i++) {
                    let index = res.indexOf(onlyFirstMarkerClass[i]);
                    if (index >= 0) {
                        res.splice(index, 1);
                    }
                }
                return res;
            },
            getAlertHighlightClassNames: function (alert,targetType) {
                let classArray = [this.alertHighlightClass];
                if (AlertDisplayStatus.EXPAND === alert.calculateInfo.status) {
                    classArray.push('_3GrEs');
                }

                if (this.isSingleMode) {
                    // const lineBoxType = this.getAlertShowType(alert)
                    // classArray.push(lineBoxType === AlertShowType.VERTICAL ? 'verticalLineBox' : '_3F-Wk');
                    classArray.push('single-mode-alert')

                    if (MarkTargetType.ORIGIN_FILE === targetType){
                        classArray.push('target-origin')
                        if (DiffAlertType.INSERT_BEFORE === alert.alertType) {
                            classArray.push('marker_color_insert');
                            classArray.push('insert_before_alert');
                        }
                        if (DiffAlertType.INSERT_AFTER === alert.alertType) {
                            classArray.push('marker_color_insert');
                            classArray.push('insert_after_alert')
                        }
                        if (this.isDeleteAlert(alert)) {
                            classArray.push('marker_color_delete');
                            classArray.push('delete_alert')
                        }
                        if(DiffAlertType.REPLACE === alert.alertType) {
                            classArray.push('marker_color_replace');
                        }
                    }else if (MarkTargetType.DIFF_FILE === targetType) {
                        classArray.push('target-diff')
                        if (this.isInsertAlert(alert)) {
                            classArray.push('marker_content_border');
                            classArray.push('marker_border_color1');
                        }
                        if (DiffAlertType.DELETE_BEFORE === alert.alertType) {
                            classArray.push('marker_left_insert');
                            classArray.push('marker_insert_color2')
                        }
                        if (DiffAlertType.DELETE_AFTER === alert.alertType) {
                            classArray.push('marker_right_insert');
                            classArray.push('marker_insert_color2')
                        }
                        if(DiffAlertType.REPLACE === alert.alertType) {
                            classArray.push('marker_content_border');
                            classArray.push('marker_border_color1');

                            classArray.push('marker_left_insert');
                            classArray.push('marker_insert_color2')
                        }
                    }
                }else{
                    classArray.push('double-mode-alert');
                    if (MarkTargetType.ORIGIN_FILE === targetType){
                        if (this.isInsertAlert(alert)) {
                            classArray.push('marker_color_transparent');
                        }
                        if (this.isDeleteAlert(alert)) {
                            classArray.push('marker_color_delete');
                        }
                        if(DiffAlertType.REPLACE === alert.alertType) {
                            classArray.push('marker_color_delete');
                        }
                    }else if (MarkTargetType.DIFF_FILE === targetType) {
                        if (this.isInsertAlert(alert)) {
                            classArray.push('marker_color_insert');
                        }
                        if (this.isDeleteAlert(alert) ) {
                            classArray.push('marker_color_transparent');
                        }
                        if(DiffAlertType.REPLACE === alert.alertType) {
                            classArray.push('marker_color_insert');
                        }
                    }
                }
                return classArray
            },
            getDiffBoxClass:function(alert){
                let result = {}
                result['active'] = alert.calculateInfo.status === AlertDisplayStatus.EXPAND
                return result
            },
            getDiffAlertTypeDesc: function (alert) {
                if (this.isDeleteAlert(alert)) {
                    return "删除"
                }else if (this.isInsertAlert(alert)) {
                    return "插入"
                }else if (alert.alertType === DiffAlertType.REPLACE) {
                    return "替换"
                }
                return "";
            },
            getDeleteText:function(alert){
                if (this.isDeleteAlert(alert)) {
                    return alert.sourceText.replace(/\s/g,'');
                }else if (this.isInsertAlert(alert)) {
                    return ""
                }else if (alert.alertType === DiffAlertType.REPLACE) {
                    return alert.sourceText.replace(/\s/g,'');
                }
            },
            getInsertText: function (alert) {
                if (this.isDeleteAlert(alert)) {
                    return ""
                }else if (this.isInsertAlert(alert)) {
                    return alert.replaceText.replace(/\s/g,'');
                }else if (alert.alertType === DiffAlertType.REPLACE) {
                    return alert.replaceText.replace(/\s/g,'');
                }
            },
            alertClick: function (alertObj) {
                let time = 500
                // 如果marker对应的页面都还没有加载出来，则跳到对应页面去加载。
                let originFileMarkerNotReady,diffFileMarkerNotReady;
                diffFileMarkerNotReady = this.scrollToPageIfNeed(alertObj, MarkTargetType.DIFF_FILE);

                if (this.isDoubleMode) {
                    originFileMarkerNotReady = this.scrollToPageIfNeed(alertObj, MarkTargetType.ORIGIN_FILE);
                    if (originFileMarkerNotReady ) {
                        // 一旦有一个pdf没准备好，则直接返回。
                        return;
                    }
                }
                if (diffFileMarkerNotReady) {
                    // 一旦有一个pdf没准备好，则直接返回。
                    return;
                }
                this.alignAlertMarker(alertObj);
                this.expandAlert(alertObj.id);
            },
            alignAlertMarker:function(alert){
                let diffMarker = this.getHighlightJItemByIdAndMarkerTargetType(alert.id, MarkTargetType.DIFF_FILE);
                let alertBox = this.getAlertBoxById(alert.id);
                let alertScroller = this.getAlertBoxScroller();
                let diffScroller = this.getScrollerByMarkerTargetType(MarkTargetType.DIFF_FILE)
                let scrollerHeight= diffScroller.height();
                let scrollerOffsetTop = diffScroller.offset().top;
                let boundValue = scrollerHeight * 0.2;// 上下百分之20都属于靠边，需要挪到中间去。
                let animateTime = 500;
                let alertOffset = alertBox.offset().top

                if (this.isHighlightEleOutOfScreen(diffMarker)) {
                    diffMarker[0].scrollIntoView(true)
                }

                if (this.isDoubleMode) {
                    // 双文件模式调整位置。
                    let originMarker = this.getHighlightJItemByIdAndMarkerTargetType(alert.id, MarkTargetType.ORIGIN_FILE);
                    if (this.isHighlightEleOutOfScreen(originMarker)) {
                        originMarker[0].scrollIntoView(true)
                    }
                    let originMarkerOffsetTop = originMarker.offset().top
                    let diffMarkerOffsetTop = diffMarker.offset().top
                    let minus = (diffMarkerOffsetTop - originMarkerOffsetTop) / 2;

                    if (Math.abs(minus) < 5) {
                        // 说明两个marker是一致的
                        if (Math.abs(originMarkerOffsetTop - scrollerOffsetTop) < boundValue || Math.abs(scrollerHeight + scrollerOffsetTop - originMarkerOffsetTop) < boundValue) {
                            // 如果太靠上或者太靠下，都移到中间去。
                            let destOffset = (scrollerHeight + scrollerOffsetTop) / 2;
                            this.scrollToRelativePositionByMarkerTargetType(MarkTargetType.ORIGIN_FILE, originMarkerOffsetTop - destOffset, animateTime)
                            this.scrollToRelativePositionByMarkerTargetType(MarkTargetType.DIFF_FILE, diffMarkerOffsetTop - destOffset, animateTime);
                            this.scrollToRelativePosition(alertScroller, alertOffset - destOffset, animateTime);
                        }else{
                            //说明两个marker是一致的，并且在视觉区域中间，那么只需要调整alert box的位置。
                            this.scrollToRelativePosition(alertScroller, alertOffset - originMarkerOffsetTop, animateTime);
                        }
                    }else{
                        this.scrollToRelativePositionByMarkerTargetType(MarkTargetType.ORIGIN_FILE,-minus,animateTime)
                        this.scrollToRelativePositionByMarkerTargetType(MarkTargetType.DIFF_FILE,minus,animateTime)
                        let destOffset = originMarkerOffsetTop + minus;
                        this.scrollToRelativePosition(alertScroller, alertOffset - destOffset, animateTime);
                    }
                }else {
                    // 单文件模式调整位置，
                    let diffMarkerOffsetTop = diffMarker.offset().top
                    if (Math.abs(diffMarkerOffsetTop - scrollerOffsetTop) < boundValue || Math.abs(scrollerHeight + scrollerOffsetTop - diffMarkerOffsetTop) < boundValue) {
                        // 如果太靠上或者太靠下，都移到中间去。
                        let destOffset = (scrollerHeight + scrollerOffsetTop) / 2;
                        this.scrollToRelativePositionByMarkerTargetType(MarkTargetType.DIFF_FILE, diffMarkerOffsetTop - destOffset, animateTime);
                        this.scrollToRelativePosition(alertScroller, alertOffset - destOffset, animateTime);
                    } else {
                        //说明在视觉区域中间，那么只需要调整alert box的位置。
                        this.scrollToRelativePosition(alertScroller, alertOffset - diffMarkerOffsetTop, animateTime);
                    }
                }
            },
            scrollToRelativePositionByMarkerTargetType:function(markerTargetType,addScrollTop, time){
                let scroller = this.getScrollerByMarkerTargetType(markerTargetType)
                return this.scrollToRelativePosition(scroller,addScrollTop,time)
            },
            scrollToRelativePosition: function (scroller,addScrollTop, time) {
                let currentTop = scroller.scrollTop()
                let realScrollTop = currentTop + addScrollTop
                this.scrollToAbsolutePosition(scroller,realScrollTop, time)
                return realScrollTop
            },
            scrollToAbsolutePosition: function (scroller,scrollTop, time) {
              let originContractTool = this.$refs.originContractTool;
              let diffContractTool = this.$refs.diffContractTool
              if(!this.isSingleMode){
                //如果不是单文件模式,禁止emit事件,禁止容器滚动
                originContractTool.preventScrollListener = true;
                originContractTool.freezeScroll = true;
                diffContractTool.preventScrollListener = true;
                diffContractTool.freezeScroll = true;
              }


                if (time > 0) {
                    scroller.animate({'scrollTop': scrollTop}, time);

                    if(this.unfreezeTimeout){
                      clearTimeout(this.unfreezeTimeout);
                    }
                    this.unfreezeTimeout = setTimeout(()=>{
                      this.$refs.originContractTool.enableScrollListener(0);
                      this.$refs.diffContractTool.enableScrollListener(0);
                    },time);

                } else {
                    scroller.scrollTop(scrollTop);
                    this.$refs.originContractTool.enableScrollListener();
                    this.$refs.diffContractTool.enableScrollListener();
                }
            },
            getScrollerByMarkerTargetType:function(markerTargetType){
                let scrollerId = this.getScrollerIdByMarkerTargetType(markerTargetType);
                return $(`#${scrollerId}`);
            },
            getAlertBoxScroller:function(){
                return $(`#${this.alertContainerId}`)
            },
            getScrollerIdByMarkerTargetType:function(markerTargetType){
                if (MarkTargetType.DIFF_FILE === markerTargetType) {
                    return this.diffPdfContainerId
                }
                return this.pdfContainerId;
            },
            /**
             * 根据alertId和markerTargetType获取marker
             */
             getHighlightJItemByIdAndMarkerTargetType:function(alertId, markerTargetType){
                let viewerId = this.pdfViewerId;
                if (MarkTargetType.DIFF_FILE === markerTargetType) {
                    viewerId = this.diffFilePdfViewerId
                }
                return $(`#${viewerId} .${this.alertHighlightClass}[data-id=${alertId}]`);
            },
            /**
             * 如果alert对应的marker所在页还没有加载，则跳到对应页面去。
             */
            scrollToPageIfNeed: function (alertObj, markerTargetType) {
                let pdfInfo = this.getPdfInfoByTargetType(markerTargetType);
                let pageIndexInfo = this.getPageIndexInfoByTargetType(alertObj,markerTargetType);

                const visiblePageNum = pdfInfo.getRenderFinishPageNum(true);
                if (visiblePageNum.indexOf(pageIndexInfo[0].startPage) < 0) {
                    this.expandAlert(alertObj.id)
                    pdfInfo.scrollByPage(pageIndexInfo[0].startPage)
                    return true;
                }
                return false;
            },
            // 判断是否点击到错误文字，并决定是否触发点击事件。
            judgeHighlightClick: function (e) {
                let alertHighlights = this.getAlertHighlights();
                let clickAlertIds = []
                //获取点击点在哪些alert的高亮区域里面
                for (let i = 0; i < alertHighlights.length; i++) {
                    let highlightEleRect = alertHighlights[i].getBoundingClientRect()
                    if (e.clientX <= highlightEleRect.right && e.clientX >= highlightEleRect.left - 3
                        && e.clientY <= highlightEleRect.bottom + 3 && e.clientY >= highlightEleRect.top) {
                        clickAlertIds.push($(alertHighlights[i]).attr('data-id'))
                    }
                }
                if (clickAlertIds.length === 0) {
                    return
                }
                let minStartAlertId = null;
                let maxStart = -1;

                for (let i = 0; i < clickAlertIds.length; i++) {
                    let alert = this.getAlertById(clickAlertIds[i]);
                    //这里忽略了多个markRange的情况（要想彻底解决这个问题还是得找到点击的地方在哪个字周围）
                    if (alert.markRanges[0].start > maxStart) {
                        //当前提示的start更大，更精准
                        maxStart = alert.markRanges[0].start
                        minStartAlertId = clickAlertIds[i]
                    }
                }
                if(minStartAlertId){
                    this.highlightClick(minStartAlertId, 50);
                }
            },
            getAlertHighlights:function () {
                return $(`.${this.alertHighlightClass}`);
            },
            getAlertById:function(alertId){
                return this.alertDataMap.get(alertId);
            },
            highlightClick: function (currentId, time) {
                let highlight = this.getAlertHighlightJItem(currentId)
                if (highlight) {
                    this.expandAlert(currentId)
                    this.alertClick(this.getAlertById(currentId))
                }
            },
            //pdf js 的某一页已经渲染完毕
            sourceFilePageRendered: function(pageNum){
                let highlightAlertData = this.alertData;
                let expandAlert =  this.getExpandAlert();

                this.refreshErrorHighlight(highlightAlertData);
                if (expandAlert && expandAlert.calculateInfo.pageIndexInfo && expandAlert.calculateInfo.pageIndexInfo[0].startPage === pageNum) {
                    //如果展开的提示和加载出来的页面是同一页，则滚动过去。
                    this.highlightClick(expandAlert.id, 500);
                }
            },
            diffFilePageRendered:function(pageNum){
                let highlightAlertData = this.alertData;
                let expandAlert =  this.getExpandAlert();

                this.refreshErrorHighlight(highlightAlertData);
                if (expandAlert && expandAlert.calculateInfo.diffPageIndexInfo && expandAlert.calculateInfo.diffPageIndexInfo[0].startPage === pageNum) {
                    //如果展开的提示和加载出来的页面是同一页，则滚动过去。
                    this.highlightClick(expandAlert.id, 500);
                }
            },
            lineHighlight:function(alertId){
                let expandAlert = this.getExpandAlert();
                if (expandAlert) {
                    let oldHighlight = this.getAlertHighlightJItem(expandAlert.id);
                    oldHighlight.removeClass('_3GrEs')
                }

                let newHighlight = this.getAlertHighlightJItem(alertId);
                newHighlight.addClass('_3GrEs')
            },
            addEditorEventListener: function () {
                let self = this
                // this.getEditorJElement().off('click').on('click',function (e) {
                $(".pdfViewer").off('click').on('click',function (e) {
                    if (!(e.pageX || e.pageY)) {
                        // 如果不是一个正常的点击事件（有可能是有focus触发的）
                        return
                    }
                    self.judgeHighlightClick(e)
                })
            },
            /**
             * 错误关键词是否在屏幕之外
             */
            isHighlightEleOutOfScreen: function (highlightEle) {
                let scrollerOffset = this.getScrollerOffsetTop() // 正文容器到可见区域顶部的距离
                let jqueryObj = $(highlightEle)
                let markerOffset = jqueryObj.offset().top // 当前错误关键词到可见区域顶部的距离
                return markerOffset > this.getScrollerHeight() || markerOffset < scrollerOffset
            },
            getAlertHighlightJItem:function(alertId){
                return $(`.${this.alertHighlightClass}[data-id=${alertId}]`);
            },
            expandAlert: function (alertId) {
                if (this.alertData.length <= 0) {
                    return
                }
                //清除旧的提示划线高亮状态。
                this.clearActiveHighlight();
                this.alertData.forEach(currentAlertObj => {
                    currentAlertObj.calculateInfo.status =
                        alertId === currentAlertObj.id
                            ? AlertDisplayStatus.EXPAND
                            : AlertDisplayStatus.COLLAPSE
                })
                this.lineHighlight(alertId)
            },
            // 正文滚动的距离
            getScrollerScrollTop: function () {
                // todo 待修改
                return this.getScrollerJItem().scrollTop()
            },
            getScrollerJItem: function () {
                // todo 待修改
                return $('#' + this.scrollerId)
                // return $('#' + this.diffScrollerId)
            },
            getEditorJElement: function () {
                return $('#' + this.editorId)
            },
            // 正文容器到可见区域顶部的距离
            getScrollerOffsetTop: function () {
                return this.getScrollerJItem().offset().top;
            },
            // 正文容器高度
            getScrollerHeight: function () {
                return this.getScrollerJItem().height()
            },
            clearActiveHighlight: function () {
                const highlightBox = $(`.${this.alertHighlightClass}._3GrEs`);
                if (highlightBox) {
                    highlightBox.removeClass('_3GrEs');
                }
            },
            getAlertBoxById:function(alertId){
                return $(this.$refs['alertInfoBox' + alertId])
            },
            getExpandAlert: function () {
                for (let i = 0; i < this.alertData.length; i++) {
                    if (this.alertData[i].calculateInfo.status === AlertDisplayStatus.EXPAND) {
                        return  this.alertData[i]
                    }
                }
                return  null;
            },
          acceptSignedDiff(){
              acceptSignedDiff(this.contractId,{pendingVersionId:this.diffVersionId}).then(res=>{
                if(res.data.code==0){
                  this.$router.back();
                }
              })
          },

          cancelSignedDiff(){
            cancelSignedDiff(this.contractId,{pendingVersionId:this.diffVersionId}).then(res=>{
              if(res.data.code==0){
                this.$router.back();
              }
            })
          },
          async updateTip(){
              let viewRes = await getContractViewDetail(this.contractId);
              if(viewRes.data.code != 0) return;
              let latestVersionId = viewRes.data.data.version.id;

              const getTip = async (versionId) => {
                let res = await getContractVersion(versionId);
                if(res.data.code != 0) return '';
                const versionInfo = res.data.data;
                return 'V' + versionInfo.bigVersion + (versionInfo.smallVersion ? '.'+versionInfo.smallVersion : '') + ' ' + (versionInfo.createTime.slice(0,-3));
              }

              const versionInfo = viewRes.data.data.version;
              if(this.originVersionId == latestVersionId) {
                this.leftVersionTip = 'V' + versionInfo.bigVersion + ' ' + (versionInfo.createTime.slice(0,-3)) + '(最新版)';
              }else{
                this.leftVersionTip = await getTip(this.originVersionId);
              }

              if(this.diffVersionId == latestVersionId) {
                this.rightVersionTip = 'V' + versionInfo.bigVersion + ' ' + (versionInfo.createTime.slice(0,-3)) + '(最新版)';
              }else{
                this.rightVersionTip = await getTip(this.diffVersionId);
              }

          }

        },
      beforeRouteLeave(){
          if(this.signDiffTips){
            this.signDiffTips.close();
          }
      }
    }
</script>

<style scoped>
    @import url('../../assets/css/alert.css');
    #mainContainer{
      --pdfToolBarHeihgt:43px;
      --alterContainerWidth:200px;
    }
    .content-wrap{
      display: flex;
      height: 100vh
    }
    #pdf-area{
      position:relative;
      height: 100%;
      width: calc( 100% - var(--alterContainerWidth));
    }
    .pdf-wraps{
      display: flex;
      justify-content: space-around;
      height: 100%;
      width: 100%;
      height: calc(100% - var(--pdfToolBarHeihgt));
      background: var(--page-gray);
    }
    .pdf-wrap{
      width: 50%;
      height: 100%;
      position: relative;
    }
    #pdf-wrap-new.single-mode{
      width: 100%;
    }
    .pdf-container {
        position: absolute;
        width: 100%;
        height: 100%;
        text-align: left;
        overflow: auto;
    }
    .pdf-fileTip{
      display: flex;
      justify-content: center;
      align-items: center;
      padding-top: 9px;
      font-size: 14px;
      color: #3c4146;
      width: 100%;
    }
    .pdf-fileTip-icon{
      height: 26px;
      width: 26px;
      flex-shrink: 0;
    }
    .pdf-fileTip-fileName{
      white-space: nowrap;
      overflow-x: hidden;
      text-overflow: ellipsis;
      margin-left: 5px;
      display: inline-block;
      max-width: calc(100% - 26px);
    }
    .tool-bar-left-item{
      flex-grow: 1;
      flex-shrink:1;
      position: relative;
      max-width: 50%;
    }

    .diffResultAggravation{
      position: absolute;
      top: 5px;
      right: 5px;
      height: 1em;
      display: flex;
      font-size: 14px;
      line-height: 1em;
    }
    .changeText_hiddenContentSummary{
      font-style: italic;
      color: #aaa;
      white-space: nowrap;
    }
    .optionDiff-delete{
      color: rgb(236, 81, 99);
    }
    .optionDiff-add{
      color: rgb(4, 135, 126);
    }
    .optionDiff{
      padding: 0 8px;
      min-width: 20px;
      text-align: center;
    }
    .optionDiff-add{
      padding-right: 3px;
    }
    .optionDiffNoChange{
      color: #ccc !important;
    }
    #tool-bar-left-hr,.optionDiff-hr,.contractToolBar >>> .column-hr{
      height: 100%;
      width: 1px;
      margin: 0;
      border: 0;
      background: #ccc;
      flex-shrink:0;
      flex-grow: 0;
    }
    .pdf-tool-bar{
      height: var(--pdfToolBarHeihgt);
      width: 100%;
      box-sizing: border-box;
      border-width: 1px;
      border-style: solid;
      border-top: 0;
    }
    .tool-bar-left{
      border-color: transparent #ccc #ccc;
      display: flex;
    }
    .tool-bar-right{
      border-color: transparent transparent #ccc;
      padding: 0 10px;
      font-size:12px;
      position: relative;
    }

    .ptb-locate-center{
      height:fit-content;
      position: absolute;
      display: flex;
      justify-content: space-between;
      width: calc(100% - 20px);
      align-items: baseline;
      margin: auto;
      top:0;
      bottom:0;
    }

    .tbr-changesCount{
      color:rgb(96, 98, 102);
      word-break: keep-all;
      margin:0;
    }


     .replaceNoticeContainer .insertReplaceText {
         height:auto;
         min-height:32px;
         text-overflow: initial;
         white-space: pre-wrap;
     }
    .pdfViewer{
        width: 100%;
        height: auto;
        top: 0;
        /*overflow: hidden;*/
        text-align: left;
        display: inline-block;
        vertical-align: top;
        transition: width .5s;
    }
    .alter-area{
      width: var(--alterContainerWidth);
    }

    .alert-cust-container{
        overflow: auto;
        /*display: inline-block;*/
        height: 100%;
        overflow: auto;
        right:0;
        text-align: left;
        width: 100%;
        transition: margin-top ease-in-out .5s;
        box-shadow: 0 2px 6px 1px #ccc;
    }
    .alert-cust-container::-webkit-scrollbar-thumb{
      background: #ccc !important;
    }
    .insertReplaceText pre,.deleteWords pre,.replaceSourceText pre,.replaceText pre{
        white-space: pre-wrap;
    }
    .replaceNoticeContainer .deleteWords{
        height:auto;
        max-width:250px;
        overflow:auto;
        white-space: pre-wrap;
    }
    .replaceNoticeContainer .replaceSourceText{
        height: auto;
        max-width: 40%;
    }

    .replaceNoticeContainer .replaceText{
        height: auto;
        max-width: 40%;
    }

    #tool-bar-left-hr.single-mode,#pdf-wrap-old.single-mode,.tool-bar-left-item.single-mode{
      display: none;
    }
    .tool-bar-left.single-mode{
      justify-content: center;
    }



</style>

<style>
    .insert_before_alert._3F-Wk:after{
        content:'\e612'!important;
        font-family: iconfont;
        bottom:4px!important;
        left:-10px;
        font-size:18px;
        font-weight: bolder;
        background: none;
        color:#5ec9db;
    }
    .insert_after_alert._3F-Wk:after{
        content:'\e612'!important;
        font-family: iconfont;
        bottom:4px!important;
        /*right:-10px;*/
        left:calc(100% - 10px);;
        font-size:18px;
        font-weight: bolder;
        background: none;
        color:#5ec9db;
    }
    .delete_alert._3F-Wk:after{
        bottom:40%!important;
    }




    .single-mode-alert,.double-mode-alert{
        position: absolute !important;
        margin: 0 !important;
        padding: 0 !important;
        /*border: 0 !important;*/
    }
    .single-mode-alert:before,.double-mode-alert:before{
        visibility: visible !important;
        content: '' !important;
        position: absolute !important;
        width: 100% !important;
        height: 100% !important;
        left:0;
    }
    .single-mode-alert._3GrEs:before,.double-mode-alert._3GrEs:before{
        outline: 2px solid #0b7dda;
    }
    .double-mode-alert.marker_color_transparent._3GrEs:before{
        outline: none;
    }

    .marker_content_border{
        position: absolute;
        border-bottom: 3px solid;
    }

    .marker_border_color1{
        border-bottom-color: rgba(91,193,165,1);
    }
    .marker_border_color2{
        border-bottom-color: rgba(255,81,102,1);
    }
    .marker_right_insert:after{
        position: absolute;
        content:'\e601'!important;
        font-family: iconfont;
        bottom:-6px!important;
        right:-8px;
        /*left:calc(100% - 10px);;*/
        font-size:8px;
        font-weight: bolder;
        background: none;
        color:#5ec9db;
    }
    .marker_left_insert:after{
        position: absolute;
        content:'\e601'!important;
        font-family: iconfont;
        bottom:-8px!important;
        left:-5px;
        font-size:8px;
        font-weight: bolder;
        background: none;
        /*color:#5ec9db;*/
    }
    .marker_insert_color2:after{
        color:rgba(255,81,102,1);
    }



    .marker_color_transparent:before {
        background: transparent;
    }
    .marker_color_delete:before {
        background: rgba(255,81,102,0.4);
    }
    .marker_color_insert:before {
        background: rgba(91,193,165,0.4);
    }


    .diffResultType{
        color: #aaa;
        font-weight: 500;
        margin: 0 .25rem;
        line-height:1.5;
    }
    .diffText{
        color: #2a2a2a;
        line-height:1.5;
        margin: .5rem .75rem;
    }
    .deleteText,.insertText{
      color: #2a2a2a;
      padding: 4px;
      word-break: break-all;
    }
    .insertText{
        background: rgba(91,193,165,.4);
    }
    .deleteText{
        background: rgba(255,81,102,.4);
    }
    .diffAlertBox{
        color: rgba(0,0,0,.65);
        background: #fff;
        border: 1px solid rgba(0,0,0,.2);
        border-radius: 4px;
        margin: 12px 12px;
        position: relative;
    }
    .diffAlertBox:hover{
        outline: 1px solid #42a5f5;
        border-color: transparent
    }
    .diffAlertBox.active{
        outline: 2px solid #0b7dda;
        border-color: transparent
    }

    .pdfViewer .page {
        /*direction: ltr;*/
        width: 100%;
        /*height: 1056px;*/
    }
    .single-mode-tool{
      display: none;
    }
    #noAlert{
      height: 100%;
    }
    #noAlertContent{
      display: flex;
      height: 100%;
      flex-direction: column;
      position: relative;
    }
    #noAlertContent #no-diff-img{
      transform: scale(2);
      transform-origin: top;
      /*position: absolute;*/
      /*top: 20%;*/
      /*left: 0;*/
    }
    .no-diff-container{
      width: 80%;
      margin: 0 auto;
      overflow: hidden;
      height: 100%;
    }
    .switch-mode-item:hover{
      font-weight: bold !important;
      color:var(--lightBlue) !important;
    }
    /**/
</style>
