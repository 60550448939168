<template>
  <div class="toolBarContainer" ref="container">
    <div class=" flexible-wrap">
      <div class=" toolFloor ">
        <div class="toolsContainer">
          <div class="toolEntity singleBtnTool" :class="{'disable-hover':!showBackBtn}"  @click="backBtnClicked">
            <svg-icon name="arrow-left" height="80%" width="80%" v-if="showBackBtn"></svg-icon>
          </div>
          <div class="toolEntity singleBtnTool" :class="{'singleBtnTool--active':showSearchToolAction}" ref="searchToolBtn" id="searchToolBtn" @click="toggleSearchBar">
            <svg-icon name="search"   height="100%" width="100%"></svg-icon>
          </div>
        </div>
        <div class="toolsContainer" align="left">
          <div class="toolEntity singleBtnTool" :class="{btnDisable:toolPageIndex == 1}" @click="previousPage">
            <svg-icon name="upperArror_long"  height="100%" width="100%"></svg-icon>
          </div>
          <div class="toolEntity singleBtnTool" :class="{btnDisable:toolPageIndex == totalPage}" @click="nextPage">
            <svg-icon name="belowArror_long"  height="100%" width="100%"></svg-icon>
          </div>
<!--          <div class="toolEntity marginLeft" id="pageSwitch" >-->
<!--            <input ref="pageInput" @keydown="catchKey" @change="checkPageIndex" :value="toolPageIndex"/>-->
<!--            <span>{{` / ${totalPage}`}}</span>-->
<!--          </div>-->
          <div class="toolEntity marginLeft" >
            <PDFPageDisplay
            :total-page="totalPage"
            v-model:toolPageIndex="toolPageIndex"
            ></PDFPageDisplay>
          </div>
        </div>
        <div class="toolsContainer tipText"><p>{{tip.text + ' ' + tip.tip}}</p></div>
        <div class="toolsContainer" align="right">
          <div class="toolEntity singleBtnTool"
               :class="{btnDisable:!getIndexOfScaleOptions(toolScaleValue)}"
               @click="narrowScale">
            <svg-icon name="minus"  height="100%" width="100%"></svg-icon>
          </div>
          <div class="toolEntity singleBtnTool"
               :class="{btnDisable:getIndexOfScaleOptions(toolScaleValue) == numberTypeScaleOptions.length - 1}"
               @click="magnifyScale">
            <svg-icon name="plus"  height="100%" width="100%"></svg-icon>
          </div>
          <div class="toolEntity selectionTool marginLeft">
            <el-select class="tool-el-selector" v-model="toolScaleValue" placeholder="选择尺寸"  size="mini">
              <el-option
                  v-for="item in scaleOptions"
                  :key="item.value"
                  :label="item.name"
                  :value="item.value"
              >
              </el-option>
            </el-select>
          </div>
          <div class="toolEntity switchFileTypeTool marginLeft">
            <el-select class="tool-el-selector" v-model="toolFileType" :disabled="!isPDF" placeholder="选择文件类型" size="mini">
              <el-option
              label="文字版"
              value="text"
              ></el-option>
              <el-option
              label="扫描版"
              value="scan"
              ></el-option>
            </el-select>
          </div>

        </div>
<!--        <div class="toolsContainer">-->
<!--          <div class="toolEntity singleBtnTool" @click="printPdf">-->
<!--            <svg-icon name="print"  height="90%" width="90%"></svg-icon>-->
<!--          </div>-->
<!--          <div class="toolEntity singleBtnTool">-->
<!--            <svg-icon name="downLoad"  height="90%" width="90%"></svg-icon>-->
<!--          </div>-->
<!--        </div>-->
      </div>
    </div>

    <div class="actionFloor" ref="actions" v-show="showSearchToolAction">
      <PDFBriefSearcher :enable-options="true" ref="searcher" @closeSearchBar="toggleSearchBar" v-if="pdfInfo" :pdf-info="pdfInfo" :initial-search-content="''"></PDFBriefSearcher>
    </div>

  </div>
</template>

<script>
import SvgIcon from "../SvgIcon/svgIcon";
import {debounce, getBrowser, getHashColor, MetaLogger} from "../../util";
import {toRaw} from 'vue'
import {colorLog,DomEventListenerManager} from "../../util";
import PDFBriefSearcher from "../../views/contract/PDFBriefSearcher";
import PDFPageDisplay from "./PDFPageDisplayer";
// pdf tool的广播事件
const ToolEvents = {
  MOVE:0,
  SCALE_CHANGE:1,
  FILE_TYPE_CHANGE:2
}
export default {
  name: "ContractDiffToolBar",
  props:['pageIndex','scaleOptions','scaleValue','emitter','tip','fileType','isPDF','showBackBtn'],
  components:{PDFPageDisplay, PDFBriefSearcher, SvgIcon},
  data(){
    return {
      inhibitJumpPage:false,
      toolPageIndex:1,
      totalPage:0,
      toolScaleValue:'auto',
      toolFileType:'text',
      targetScrollTop:0,
      pdfInfo:null,
      //切换组件显隐的布尔值
      showSearchToolAction:false,

      numberTypeScaleOptions:this.scaleOptions.filter(item => !isNaN(item.value)),

      //保存方法得到的变量
      pdfContainerDom:null,
      pdfViewerDom:null,
      viewerScrollHeightHiddenPart:0,

      //子组件特殊的状态
      preventScrollListener:false,
      unfreezeTimeout:null,
      frameComplete:true,
      freezeScroll:false,
      domEventListenerManager:null,

      //保存handler
      scaleChangeHandler:null,
      moveHandler:null,
    }
  },
  created() {
    //初始化
    this.initValue();
  },
  mounted() {
  },
  beforeUnmount() {
    this.domEventListenerManager.destroy();
  },
  watch:{
    showSearchToolAction(newVal){
      //每次都搜索框更新位置
      if(newVal) this.updateSearchBarPosition();
    },
    toolScaleValue(newVal){
      this.setPdfScale(newVal);
      this.dispatch(ToolEvents.SCALE_CHANGE,{source:this,scale:newVal});
      this.$emit('scaleChange',newVal);
    },
    toolFileType(newVal){
      this.$emit('fileTypeChange',newVal);
    },
    /**
     * watch从父组件传来的值
     * */
    scaleValue(newVal){
      this.toolScaleValue = newVal;
    },
    toolPageIndex(newVal){
      if(this.inhibitJumpPage) return this.inhibitJumpPage = false;
      this.setPdfPage(Number(newVal));
    }
  },
  methods:{
    /**
     * 用从props传来的属性初始化组件自己的属性
     */
    initValue(){
      this.toolPageIndex = this.pageIndex || 1;
      this.toolScaleValue = this.scaleValue || 'auto';
      this.toolFileType = this.fileType || 'text';
    },
    initEventListeners(){

    },
    updateSearchBarPosition(){
      this.$refs.actions.style.setProperty('left',
          this.$refs.searchToolBtn.getBoundingClientRect().left
          - this.$refs.container.getBoundingClientRect().left
          + 'px')
    },
    backBtnClicked(){
      this.$router.back();
    },
    /**
     * 让pdf平滑地滚动
     * **/
    scrollToTargetScrollTopSmoothly(duration,isDown,callback){
      let lastTime = Date.now();
      const endAt = lastTime + duration;
      let pdfContainerDom = this.pdfContainerDom;
      let beginning = pdfContainerDom.scrollTop;
      let ending = this.targetScrollTop;
      let distanceSpan = this.targetScrollTop - beginning;
      let that = this;

      function play(){
        requestAnimationFrame(()=>{
          if(!that.frameComplete){
            return;
          }
          that.frameComplete = true;
          let now = Date.now();
          let timeSpan = now - lastTime;
          lastTime = now;

          let ratio = (timeSpan / duration).toFixed(3);
          let moveDistance = (distanceSpan * ratio).toFixed(3);


          let minus;
          if(isDown){
            minus = 1;
          }else{
            minus = -1;
          }

          if((pdfContainerDom.scrollTop * 1000 + moveDistance * 1000) / 1000   * minus >= ending * minus || endAt <= lastTime){
            if(that.targetScrollTop == ending){
              pdfContainerDom.scrollTop = ending;
              //当自身的pdf独立运动完后,调用方法将另一个pdf解除禁止滚动
              callback && callback();
              //开启监听自身pdf的scroll事件
              that.enableScrollListener(200);
              //取消禁止滚动
              that.freezeScroll = false;
              that.frameComplete = true;
            }
          }else{
            pdfContainerDom.scrollTop = (pdfContainerDom.scrollTop * 1000 + moveDistance * 1000 ) / 1000;
            play();
          }
        })
      }
      play();

    },
    /**
     * 允许监听scroll,不阻止emit联动事件
     * */
    enableScrollListener(time = 200){
      if(this.unfreezeTimeout){
        clearTimeout(this.unfreezeTimeout);
      }
      this.unfreezeTimeout=setTimeout(()=>{
        this.preventScrollListener = false;
      },time)
    },
    dispatch(type,data){
      this.emitter.emit(type,data);
    },
    narrowScale(){
      let index = this.getIndexOfScaleOptions(this.toolScaleValue);
      if(index > 0){
        this.toolScaleValue = this.numberTypeScaleOptions[--index].value;
      }
    },
    magnifyScale(){
      let index = this.getIndexOfScaleOptions(this.toolScaleValue);
      if(index  < this.numberTypeScaleOptions.length - 1){
        this.toolScaleValue = this.numberTypeScaleOptions[++index].value;
      }
    },
    /**
     * 获取value为数字类型的index
     * */
    getIndexOfScaleOptions(value){
      //如果value是个string,则找到value为1的index
      if(isNaN(value)){
        return this.numberTypeScaleOptions.findIndex((item)=>{
          return item.value == 1;
        })
      }

      return this.numberTypeScaleOptions.findIndex((item)=>{
        return item.value === value;
      })
    },
    previousPage(){
      if(this.toolPageIndex > 1){
        this.setPdfPage(--this.toolPageIndex);
      }

    },
    nextPage(){
      if(this.toolPageIndex < this.totalPage){
        this.setPdfPage(++this.toolPageIndex);
      }
    },
    /**
     * 由于pdf对象是异步传来的,这里用set方法来注册子组件的pdfInfo属性
     **/
    setPdfInfo(pdf){
      if(!pdf) {
        colorLog.blue('pdf文件为空')
        return;
      }
      this.emitter.off(ToolEvents.SCALE_CHANGE,toRaw(this.scaleChangeHandler));
      this.emitter.off(ToolEvents.MOVE,toRaw(this.moveHandler));


      this.domEventListenerManager?.destroy();
      // this.dispatch(ToolEvents.FILE_TYPE_CHANGE,{source:this,isInit:this.domEventListenerManager ? false : true});
      this.domEventListenerManager = new DomEventListenerManager();

      this.pdfInfo = pdf;
      this.totalPage = pdf.pdfDoc.numPages;

      this.registerPdjInfoListeners();
      this.registerEmitterListeners();
    },
    resetViewerScrollHeightHiddenPart(){
      if(!this.pdfViewerDom || !this.pdfContainerDom){
        MetaLogger.error('resetViewerScrollHeightHiddenPart 缺少dom');
        return;
      }
      this.viewerScrollHeightHiddenPart = this.pdfViewerDom.scrollHeight - this.pdfContainerDom.offsetHeight;
    },
    /**
     * 绑定pdf.js监听广播的listener
     * */
    registerPdjInfoListeners(){
      this.pdfInfo.eventBus._on('pagerendered',()=>{
        this.resetViewerScrollHeightHiddenPart();
      })
      this.pdfInfo.eventBus._on('pagechanging',(data)=>{
        this.inhibitJumpPage = true;
        this.toolPageIndex = data.pageNumber;
      })

      this.pdfContainerDom = document.getElementById(this.pdfInfo.pdfContainerId);
      this.pdfViewerDom = this.pdfInfo.pdfViewer.viewer;
      this.resetViewerScrollHeightHiddenPart();

      const eventName = getBrowser() == 'FF' ? "DOMMouseScroll" : "mousewheel";

      let color = getHashColor(this.tip.text);

      this.domEventListenerManager.registerListener(this.pdfContainerDom,eventName,(e)=>{
        //禁止滚动
        if(this.freezeScroll){
          this.targetScrollTop = this.pdfContainerDom.scrollTop;
          let scrollRatio = this.targetScrollTop / this.viewerScrollHeightHiddenPart;
          //自身的pdf不滚动,而让另一个pdf滚动
          this.dispatch(ToolEvents.MOVE,{source:this,ratio:Math.floor(scrollRatio * 1000) / 1000,independent:true,callback:()=>{
              //另一个pdf自行移动完后解除禁止滚动
              this.freezeScroll = false;
            }});
          e.preventDefault();
        }
        if(this.targetScrollTop == this.viewerScrollHeightHiddenPart){
          // colorLog.red(this.tip.text,' 滑到底了');
          //如果container已经滑到底了,让另一个container也滑到底.
          //原因:container到底以后不再触发scroll事件了,就不会联动,这时另一个出现没有滑到底的情况就没法解决.
          this.dispatch(ToolEvents.MOVE,{source:this,ratio:1,independent:true});
        }
      })

      this.domEventListenerManager.registerListener(this.pdfContainerDom,"scroll",(e)=>{
        if(this.preventScrollListener || this.freezeScroll){
          return;
        }
        this.targetScrollTop = this.pdfContainerDom.scrollTop;

        let scrollRatio = this.pdfContainerDom.scrollTop / this.viewerScrollHeightHiddenPart;
        // colorLog[color](this.tip.text,this.pdfContainerDom.scrollTop,'/',this.viewerScrollHeightHiddenPart,scrollRatio.toFixed(2));

        //两个pdf联动
        this.dispatch(ToolEvents.MOVE,{source:this,ratio:Math.ceil(scrollRatio * 1000) / 1000});
      })
    },
    /**
     * 注册emitter的listeners,用于pdf工具传
     * */
    registerEmitterListeners(){
      this.scaleChangeHandler = (data)=>{
        let { source,scale } = data;
        if(source == this){
          return;
        }
        this.toolScaleValue = scale;
      };

      this.emitter.on(ToolEvents.SCALE_CHANGE,toRaw(this.scaleChangeHandler))
      let color = getHashColor(this.tip.text);
      //监听联动MOVE事件,跟随另一个pdf一起滚动
      this.moveHandler = data =>{
        let { source,ratio,independent,callback } = data;
        if(source == this || !this.pdfContainerDom){
          return;
        }
        this.preventScrollListener = true;

        let pdfContainerDom = this.pdfContainerDom
        let viewerScrollHeightHiddenPart = this.viewerScrollHeightHiddenPart;

        let scrollTop = viewerScrollHeightHiddenPart * ratio;
        this.targetScrollTop = scrollTop;
        // colorLog[color](this.tip.text,'跟着联动',ratio,':',scrollTop);
        if(independent){
          //独立滚动
          this.scrollToTargetScrollTopSmoothly(200,this.targetScrollTop > pdfContainerDom.scrollTop,callback);
        }else{
          //联动
          pdfContainerDom.scrollTop = this.targetScrollTop;
          this.enableScrollListener();
        }

        //每次被另一个pdf联动,自动将上面preventScrollListener设为false(允许监听scroll,取消阻止emit);

      }
      this.emitter.on(ToolEvents.MOVE,toRaw(this.moveHandler));
    },
    dispatchEvent(type, findPrev = false) {
      this.pdfInfo.eventBus.dispatch("findagain", {
        source: this,
        type,
        query: this.searchContent,
        phraseSearch: true,
        caseSensitive: this.caseSensitive,
        entireWord: this.entireWord,
        highlightAll: this.highlightAll,
        findPrevious: findPrev,
      });
    },
    closeSearchBar(){
      this.showSearchToolAction = false;
      this.$refs.searcher.clearSearchContent();
    },
    openSearchBar(){
      this.showSearchToolAction = true;
      this.$nextTick(()=>{
        this.$refs.searcher.focusOnInput();
      })
    },
    toggleSearchBar(){
      if(this.showSearchToolAction){
        this.closeSearchBar();
      }else{
        this.openSearchBar();
      }
    },
    /**
     * 跳转pdf页面
     */
    setPdfPage(number){
      if(!this.pdfInfo) return;
      toRaw(this.pdfInfo.pdfViewer).currentPageNumber = number;
    },

    /**
     * 更改pdf缩放量
     * **/
    setPdfScale(value){
      if(!this.pdfInfo) return;
      //toRaw,取消Vue3的响应式(把Poxy包装的对象转换为原始对象)
      toRaw(this.pdfInfo).setScale(value);

    },
    printPdf(){
      if(!this.pdfInfo) return;
      let eventBus = this.pdfInfo.eventBus;
      eventBus.dispatch("print",{source:this});
    }
  }
}
</script>

<style scoped>

.toolBarContainer{
  --toolBarContainerHeight:10em;/* 10 * 父组件给的font-size */
  height: 100%;
  position: relative;
  box-sizing: border-box;
}
.toolsContainer:not(:first-of-type){
  flex-grow: 1;
  justify-content: center;
}
.toolFloor{
  --toolFloorPdding:1em;
  --toolWidth: 5.8em;

  width: calc(100% - 2 * var(--toolFloorPdding));
  padding: var(--toolFloorPdding);
  height: 100%;
  display: flex;
  min-width: calc(120 * 1em);
  box-sizing: border-box;
}
.toolEntity{
  height: var(--toolWidth);
  display: flex;
  align-items: center;
  box-sizing: border-box;
}
.flexible-wrap{
  height: 100%;
  width: 100%;
  overflow: visible;
}

.marginLeft{
  margin-left: 5px;
}
.tool-el-selector{
  width: 95px;
}
.btnDisable{
  color: #ccc !important;
}

.singleBtnTool{
  --singleBtnToolPadding:0px;
  transition: .5s color;
  border-radius: 2px;
  padding: var(--singleBtnToolPadding);
  width:var(--toolWidth);
  height: var(--toolWidth) !important;
  color: var(--lightBlue);
}

.selectionTool>>>.is-focus .el-input__inner{
  border-color: var(--lightBlue);
}
.el-select-dropdown .hover{
  background: var(--hoverLightBlue);
}
.el-select-dropdown .el-select-dropdown__item:hover{
  background: var(--hoverLightBlue);
}
.el-select-dropdown .selected{
  color: var(--lightBlue);
}




.singleBtnTool--active,.singleBtnTool:hover:not(.disable-hover){
  background: var(--hoverLightBlue);
}
.toolsContainer{
  height: 100%;
  display: flex;
  align-items: center;
}
.toolsContainer[align="left"]{
  justify-content: flex-start;
}
.toolsContainer[align="right"]{
  justify-content: flex-end;
}
#pageSwitch>*{
  font-size: 3em;
}
#pageSwitch>input{
  width: 50px;
  padding: 5px;
  height: 80%;
  box-sizing: border-box;
  text-align: right;
  border: 1px solid var(--lightBlue);
  border-radius: 5px;
}
#pageSwitch>span{
  white-space: pre-wrap;
  user-select:none;
  position: relative;
  font-weight: bold;
}
.actionFloor{
  position: absolute;
}
.tipText{
  min-width: 0;
}
.tipText > p{
  font-size: 12px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
</style>
